import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { CalendarEvent, Resources } from 'src/Areas/BPM/PJ/types/calendar';
import { calendarApi } from 'src/Areas/BPM/PJ/services/calendar';
// import { getTecnicos } from '../services/calendarService';

interface CalendarState {
  events: CalendarEvent[];
  resources: Resources[];
}

const initialState: CalendarState = {
  events: [],
  resources: [],
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setEvents(
      state: CalendarState,
      action: PayloadAction<CalendarEvent[]>
    ): void {
      state.events = action.payload;
    },
    createEvent(
      state: CalendarState,
      action: PayloadAction<CalendarEvent>
    ): void {
      state.events.push(action.payload);
    },
    updateEvent(
      state: CalendarState,
      action: PayloadAction<CalendarEvent>
    ): void {
      const event = action.payload;

      state.events = state.events.map(_event => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state: CalendarState, action: PayloadAction<string>): void {
      state.events = state.events.filter(event => event.id !== action.payload);
    },
    getResources(
      state: CalendarState,
      action: PayloadAction<Resources[]>
    ): void {
      state.resources = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setEvents =
  (events: CalendarEvent[]): AppThunk =>
  async (dispatch): Promise<void> => {
    // await calendarApi.setEvents(events);

    dispatch(slice.actions.setEvents(events));
  };

export const createEvent =
  (createData: {
    allDay: boolean;
    description: string;
    end: number;
    start: number;
    title: string;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await calendarApi.createEvent(createData);

    dispatch(slice.actions.createEvent(data));
  };

export const updateEvent =
  (
    eventId: string,
    update: {
      allDay?: boolean;
      description?: string;
      end?: number;
      start?: number;
      title?: string;
      resourceId?: string;
      color?: string;
    }
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await calendarApi.updateEvent({
      eventId,
      update,
    });

    dispatch(slice.actions.updateEvent(data));
  };

export const deleteEvent =
  (eventId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await calendarApi.deleteEvent(eventId);

    dispatch(slice.actions.deleteEvent(eventId));
  };

export const getResources =
  (id: string, title: string): AppThunk =>
  async (dispatch): Promise<void> => {
    /*
  const tecnicosArray: Resources[] = [];
  const data = await getTecnicos();
  data.forEach(tecnicos => {
    tecnicosArray.push({
      id: tecnicos.Tecnico.trim(),
      title: tecnicos.TecnicoFantasia.toUpperCase().trim(),
    });
  });
*/
    dispatch(slice.actions.getResources([{ id, title }]));
  };
