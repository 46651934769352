import { postRequest } from 'src/services/requests';
import configuracoesSistema from '../global/constants/configuracoesSistema';
import { ConfiguracaoSistemaDTO } from '../types/dtos/genericos';

const loadAllConfiguracoesSistema = (): void => {
  const data = {
    Configuracoes: configuracoesSistema,
  };

  const configuracoes = new Promise<ConfiguracaoSistemaDTO[]>(
    (resolve, reject) => {
      postRequest(
        '/genericos/ge/configuracao/CarregarConfiguracoes',
        data,
        resolve,
        reject
      );
    }
  );

  configuracoes.then(response => {
    if (response.length) {
      const configs = response.map(config => ({
        id: config.Sequencia,
        value:
          typeof config.Valor === 'string'
            ? config.Valor.trimEnd()
            : config.Valor,
      }));

      localStorage.setItem('configuracoes', JSON.stringify(configs));
    }
  });
};

const loadConfiguracaoSistema = (
  config: number
): Promise<ConfiguracaoSistemaDTO[]> => {
  const data = {
    Configuracoes: [config],
  };

  return new Promise((resolve, reject) => {
    postRequest(
      '/genericos/ge/configuracao/CarregarConfiguracoes',
      data,
      resolve,
      reject
    );
  });
};

export { loadAllConfiguracoesSistema, loadConfiguracaoSistema };
