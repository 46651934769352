import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { SettingsProvider } from '@cigam/template/dist/contexts/SettingsContext';
import { getItemLocalStorage } from '@cigam/template/dist/utils/getItemLocalStorage';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { store } from './store';

enableES5();

const settings = getItemLocalStorage('settings');

ReactDOM.render(
  <ReduxProvider store={store}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </ReduxProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
