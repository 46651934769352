import { getRequest, postRequest } from 'src/services/requests';
import { getItemLocalStorage } from '@cigam/template/dist/utils/getItemLocalStorage';
import {
  ParametrosPortaisDTO,
  ParametrosPortaisRetornoDTO,
} from '../types/dtos/genericos';

const getDadosPerfil = (): ParametrosPortaisRetornoDTO | null =>
  JSON.parse(localStorage.getItem('perfil') || '');

const buscarPerfil = (): void => {
  // TODO 373456/334 Hoje o buscaPerfil passa como parâmetro o dado do activeportals do arquivo de configurações,na os citada possívelmente teremos outros portais,
  // uma ideia seria quando navegar entre os portais alterar o arquivo de configurações passando o portal selecionado como o último da lista.
  const portais = getItemLocalStorage('settings').activePortals;
  const parametrosPortais = new Promise<ParametrosPortaisRetornoDTO>(
    (resolve, reject) => {
      getRequest(
        `genericos/ge/parametrosportal/BuscarPerfil?Tipo=${
          portais[portais.length - 1]
        }`,
        resolve,
        reject
      );
    }
  );
  parametrosPortais.then(response => {
    localStorage.setItem('perfil', JSON.stringify(response));
  });
};

const buscarValor = (
  Parametros: ParametrosPortaisDTO
): Promise<ParametrosPortaisRetornoDTO[]> => {
  return new Promise((resolve, reject) => {
    postRequest(
      '/genericos/ge/parametrosportal/BuscarValor',
      Parametros,
      resolve,
      reject
    );
  });
};

export { getDadosPerfil, buscarPerfil, buscarValor };
