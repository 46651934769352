import axiosDebug from 'src/utils/axiosDebug';

export const postRequest = async (
  url: string,
  data: any,
  resolve: (value: any) => void,
  reject: (reason?: any) => void
) => {
  return axiosDebug
    .post(url, data)
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error);
    });
};
