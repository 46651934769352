import React, { FC, ReactNode, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'src/store';
import { runtimeConfigs } from 'src/config/runtimeConfigs';

interface Props {
  children: ReactNode;
}

export const GuestGuard: FC<Props> = ({ children }) => {
  const account = useSelector(state => state.account);
  const [firstConfig, setFirstConfig] = useState(false);
  const location = useLocation();

  const searchEnviroment = async () => {
    const enviroment = await runtimeConfigs();

    if (!enviroment && location.pathname !== '/configuracao') {
      setFirstConfig(true);
    }
  };

  searchEnviroment();

  if (account.user) {
    return <Redirect to="/" />;
  }

  if (firstConfig) {
    return <Redirect to="/configuracao" />;
  }

  return <>{children}</>;
};
