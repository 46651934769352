import { objFromArray } from '@cigam/template/dist/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import { AppThunk } from 'src/store';
import { COLORS } from 'src/Areas/Genericos/GE/global/constants/cores';
import {
  IBoard,
  ICard,
  ICardColor,
  ICheckItem,
  IChecklist,
  IColumn,
  IComment,
  IMember,
} from '../types/kanban';

import { EstruturaDTO } from '../types/dtos/kanban';

interface KanbanState {
  isLoaded: boolean;
  isOnlyRead: boolean;
  columns: {
    byId: Record<string, IColumn>;
    allIds: string[];
  };
  cards: {
    byId: Record<string, ICard>;
    allIds: string[];
  };
  members: {
    byId: Record<string, IMember>;
    allIds: string[];
  };
}

const initialState: KanbanState = {
  isLoaded: false,
  isOnlyRead: false,
  columns: {
    byId: {},
    allIds: [],
  },
  cards: {
    byId: {},
    allIds: [],
  },
  members: {
    byId: {},
    allIds: [],
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    getBoard(state: KanbanState, action: PayloadAction<IBoard>): void {
      const board = action.payload;

      state.columns.byId = objFromArray(board.columns);
      state.columns.allIds = Object.keys(state.columns.byId);
      state.cards.byId = objFromArray(board.cards);
      state.cards.allIds = Object.keys(state.cards.byId);
      state.members.byId = objFromArray(board.members);
      state.members.allIds = Object.keys(state.members.byId);
      state.isLoaded = true;
    },
    addUserCard(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; userId: string }>
    ): void {
      const { cardId, userId } = action.payload;

      state.cards.byId[cardId].memberIds.push(userId);
    },
    removeUserCard(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; userId: string }>
    ): void {
      const { cardId, userId } = action.payload;

      state.cards.byId[cardId].memberIds = state.cards.byId[
        cardId
      ].memberIds.filter(user => user !== userId);
    },
    createColumn(state: KanbanState, action: PayloadAction<IColumn>): void {
      const column = action.payload;

      state.columns.byId[column.id] = column;
      state.columns.allIds.push(column.id);
    },
    updateColumn(state: KanbanState, action: PayloadAction<IColumn>): void {
      const column = action.payload;

      state.columns.byId[column.id] = column;
    },
    clearColumn(state: KanbanState, action: PayloadAction<string>): void {
      const columnId = action.payload;

      // cardIds to be removed
      const { cardIds } = state.columns.byId[columnId];

      // Delete the cardIds references from the column
      state.columns.byId[columnId].cardIds = [];

      // Delete the cards from state
      cardIds.forEach(cardId => {
        delete state.cards.byId[cardId];
      });

      state.cards.allIds = state.cards.allIds.filter(cardId =>
        cardIds.includes(cardId)
      );
    },
    deleteColumn(state: KanbanState, action: PayloadAction<string>): void {
      const columnId = action.payload;

      delete state.columns.byId[columnId];
      state.columns.allIds = state.columns.allIds.filter(
        _listId => _listId !== columnId
      );
    },
    createCard(state: KanbanState, action: PayloadAction<ICard>): void {
      const card = action.payload;

      state.cards.byId[card.id] = card;
      state.cards.allIds.push(card.id);

      // Add the cardId reference to the column
      state.columns.byId[card.columnId].cardIds.push(card.id);
    },
    updateCard(state: KanbanState, action: PayloadAction<ICard>): void {
      const card = action.payload;

      Object.assign(state.cards.byId[card.id], card);
    },
    moveCard(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        position: number;
        columnId: string;
      }>
    ): void {
      const { cardId, position, columnId } = action.payload;
      const sourceColumnId = state.cards.byId[cardId].columnId;

      // Remove card from source column
      state.columns.byId[sourceColumnId].cardIds = state.columns.byId[
        sourceColumnId
      ].cardIds.filter(_cardId => _cardId !== cardId);

      // If columnId exists, it means that we have to add the card to the new column
      if (columnId) {
        // Change card's columnId reference
        state.cards.byId[cardId].columnId = columnId;
        // Push the cardId to the specified position
        state.columns.byId[columnId].cardIds.splice(position, 0, cardId);
      } else {
        // Push the cardId to the specified position
        state.columns.byId[sourceColumnId].cardIds.splice(position, 0, cardId);
      }
    },
    deleteCard(state: KanbanState, action: PayloadAction<string>): void {
      const cardId = action.payload;
      const { columnId } = state.cards.byId[cardId];

      delete state.cards.byId[cardId];
      state.cards.allIds = state.cards.allIds.filter(
        _cardId => _cardId !== cardId
      );
      state.columns.byId[columnId].cardIds = state.columns.byId[
        columnId
      ].cardIds.filter(_cardId => _cardId !== cardId);
    },
    addComment(state: KanbanState, action: PayloadAction<IComment>): void {
      const comment = action.payload;
      const card = state.cards.byId[comment.cardId];

      card.comments.push(comment);
    },
    addChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklist: IChecklist }>
    ): void {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists.push(checklist);
    },
    updateChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklist: IChecklist }>
    ): void {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = card.checklists.map(_checklist => {
        if (_checklist.id === checklist.id) {
          return checklist;
        }

        return _checklist;
      });
    },
    deleteChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklistId: string }>
    ): void {
      const { cardId, checklistId } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = card.checklists.filter(
        checklist => checklist.id !== checklistId
      );
    },
    addCheckItem(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        checklistId: string;
        checkItem: ICheckItem;
      }>
    ): void {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];
      const checklist = card.checklists.find(
        _checklist => _checklist.id === checklistId
      );

      if (!checklist) {
        return;
      }

      checklist.checkItems.push(checkItem);
    },
    updateCheckItem(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        checklistId: string;
        checkItem: ICheckItem;
      }>
    ): void {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];
      const checklist = card.checklists.find(
        _checklist => _checklist.id === checklistId
      );

      if (!checklist) {
        return;
      }

      checklist.checkItems = checklist.checkItems.map(_checkItem => {
        if (_checkItem.id === checkItem.id) {
          return checkItem;
        }

        return _checkItem;
      });
    },
    deleteCheckItem(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        checklistId: string;
        checkItemId: string;
      }>
    ): void {
      const { cardId, checklistId, checkItemId } = action.payload;
      const card = state.cards.byId[cardId];
      const checklist = card.checklists.find(
        _checklist => _checklist.id === checklistId
      );

      if (!checklist) {
        return;
      }

      checklist.checkItems = checklist.checkItems.filter(
        checkItem => checkItem.id !== checkItemId
      );
    },
    setIsOnlyRead(state: KanbanState, action: PayloadAction<boolean>): void {
      const isOnlyRead = action.payload;

      state.isOnlyRead = isOnlyRead;
    },
    updateObservacao(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; observacao: string }>
    ): void {
      const { cardId, observacao } = action.payload;
      const card = state.cards.byId[cardId];

      card.observacao = observacao;
    },
  },
});

export const { reducer } = slice;

export const updateObservacao =
  (cardId: string, observacao: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const data: { cardId: string; observacao: string } = {
      cardId,
      observacao,
    };
    dispatch(slice.actions.updateObservacao(data));
  };

export const { setIsOnlyRead } = slice.actions;

const gerarCorHexadecimal = () => {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;
};

const cardColors: ICardColor[] = [];
let colors = [...COLORS];

function getLabelColor(task: any) {
  const found = cardColors.find(
    color =>
      color.order === task.CodigoOrdemServico && color.item === task.CodigoItem
  );

  if (found) {
    return found.color;
  }

  if (colors.length === 0) {
    const coresUnicas = new Set(COLORS);

    while (coresUnicas.size < 500) {
      const novaCor = gerarCorHexadecimal();
      coresUnicas.add(novaCor);
    }

    const coresFinais = Array.from(coresUnicas).slice(0, 500);
    colors = coresFinais;
  }

  const background = {
    backgroundColor: colors[0],
  };

  cardColors.push({
    order: task.CodigoOrdemServico,
    item: task.CodigoItem,
    color: background,
  });

  colors.splice(0, 1);

  return background;
}

function addCards(phase: any) {
  const cards: ICard[] = [];

  phase.Tarefas.forEach((task: any) => {
    cards.push({
      id: `task_${task.Codigo}`,
      taskId: task.Codigo,
      attachments: [],
      checklists: [],
      comments: [],
      cover: null,
      description: `Tempo previsto: ${task.TempoPrevisto}`,
      expectedTime: task.TempoPrevisto,
      due: null,
      isSubscribed: false,
      labels: [],
      columnId: phase.Codigo,
      memberIds: task.TecnicosTarefa.map((a: any) => a.CodigoTecnico.trimEnd()),
      tecnicosTarefa: task.TecnicosTarefa,
      name: `${task.Sequencia} - ${task.Descricao}`,
      codigoEmpresa: task.OrdemServico?.CodigoCliente,
      fantasia: task.OrdemServico?.Cliente.Fantasia,
      assuntoItem: task.ItemOrdemServico?.Assunto,
      observacao: task.Observacao,
      tipoOs: task.ItemOrdemServico?.CodigoTipo,
      serviceOrderCode: task.CodigoOrdemServico,
      itemCode: task.CodigoItem,
      cardColor: getLabelColor(task),
      position: 0,
      expectedDate: {
        initial: task.PrevistoInicial,
        final: task.PrevistoFinal,
      },
      date: {
        initial: task.DataInicial,
        final: task.DataFinal,
      },
      orderItem: {
        businessValue:
          task.SprintItemOrdemServico?.ValorNegocio ??
          task.ItemOrdemServico?.ValorNegocio,
        order: task.SprintItemOrdemServico?.Ordem,
      },
    });
  });

  return cards;
}

function proccessBoard(data: EstruturaDTO) {
  const kanban: { board: IBoard } = {
    board: {
      columns: [],
      cards: [],
      members: [],
    },
  };

  data.TemplateKanban.Fases.forEach(phase => {
    const hasChildren = data.TemplateKanban.Fases.some(element => {
      return element.CodigoFasePai === phase.Codigo;
    });

    let description = phase.Descricao;

    if (phase.CodigoFasePai !== 0) {
      const father = data.TemplateKanban.Fases.find(
        // Verificar com Dalmora alteração que fizeram na API que mudaram só um para string, o outro ficou number
        // eslint-disable-next-line
        element => element.Codigo == phase.CodigoFasePai
      );

      description = `${father?.Descricao ?? ''} - ${description}`;
    }

    const cards = addCards(phase);

    if (!hasChildren) {
      kanban.board.columns.push({
        id: String(phase.Codigo),
        name: description,
        cardIds: cards.map(tarefa => tarefa.id),
        backgroundColor: phase.CorHexadecimal || '#5C6BC0',
        order: phase.Ordem,
      });
    }

    kanban.board.cards = [...kanban.board.cards, ...cards];
  });

  kanban.board.members = data.TecnicosSprint.filter(
    technician => technician.Tecnico !== null
  ).map(technician => ({
    id: technician.Tecnico.Empresa.trimEnd(),
    name: technician.Tecnico.Nome,
  }));

  return kanban;
}

export const getBoard =
  (match: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const { sprint } = match.params;

    const response = await axios.get(
      `/servicos/gs/sprints/estruturakanban/${sprint}`
    );

    const { board } = proccessBoard(response.data);

    dispatch(slice.actions.getBoard(board));
  };

export const setBoard =
  (kanban: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const { board } = proccessBoard(JSON.parse(kanban));

    dispatch(slice.actions.getBoard(board));
  };

export const addUserCard =
  (cardId: string, userId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const split = cardId.split('_');

    await axios.post('/servicos/gs/tecnicostarefakanban', {
      CodigoTecnico: userId,
      CodigoTarefa: split[1],
    });

    dispatch(slice.actions.addUserCard({ cardId, userId }));
  };

export const removeUserCard =
  (cardId: string, userId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const split = cardId.split('_');

    await axios.delete('/servicos/gs/tecnicostarefakanban', {
      data: {
        CodigoTecnico: userId,
        CodigoTarefa: split[1],
      },
    });

    dispatch(slice.actions.removeUserCard({ cardId, userId }));
  };

export const createColumn =
  (name: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post('/api/kanban/board/lists/new', {
      name,
    });

    dispatch(slice.actions.createColumn(response.data.column));
  };

export const updateColumn =
  (columnId: string, update: { name: string }): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post('/api/kanban/board/list/update', {
      columnId,
      update,
    });

    dispatch(slice.actions.updateColumn(response.data.column));
  };

export const clearColumn =
  (columnId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await axios.post('/api/kanban/board/lists/clear', {
      columnId,
    });

    dispatch(slice.actions.clearColumn(columnId));
  };

export const deleteColumn =
  (columnId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await axios.post('/api/kanban/board/lists/remove', {
      columnId,
    });

    dispatch(slice.actions.deleteColumn(columnId));
  };

export const createCard =
  (columnId: string, name: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post('/api/kanban/board/cards/new', {
      columnId,
      name,
    });

    dispatch(slice.actions.createCard(response.data.card));
  };

export const updateCard =
  (
    cardId: string,
    update: {
      name?: string;
      description?: string;
      isSubscribed?: boolean;
      labels?: string[];
    }
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post('/api/kanban/board/cards/update', {
      cardId,
      update,
    });

    dispatch(slice.actions.updateCard(response.data.card));
  };

export const moveCard =
  (
    cardId: string,
    position: number,
    columnId: string,
    source: {
      index: number;
      droppableId: string;
    },
    request: Promise<any>
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(
      slice.actions.moveCard({
        cardId,
        position,
        columnId,
      })
    );

    request.catch(() => {
      dispatch(
        slice.actions.moveCard({
          cardId,
          position: source.index,
          columnId: source.droppableId,
        })
      );
    });
  };

export const deleteCard =
  (cardId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await axios.post('/api/kanban/board/cards/remove', {
      cardId,
    });

    dispatch(slice.actions.deleteCard(cardId));
  };

export const addComment =
  (userId: string, cardId: string, message: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post('/api/kanban/board/cards/comments', {
      userId,
      cardId,
      message,
    });

    dispatch(slice.actions.addComment(response.data.comment));
  };

export const addChecklist =
  (cardId: string, name: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post('/api/kanban/board/checklists/new', {
      cardId,
      name,
    });

    dispatch(
      slice.actions.addChecklist({
        cardId,
        ...response.data,
      })
    );
  };

export const updateChecklist =
  (cardId: string, checklistId: string, update: { name: string }): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post('/api/kanban/board/checklists/update', {
      cardId,
      checklistId,
      update,
    });

    dispatch(
      slice.actions.updateChecklist({
        cardId,
        ...response.data,
      })
    );
  };

export const deleteChecklist =
  (cardId: string, checklistId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await axios.post('/api/kanban/board/checklists/remove', {
      cardId,
      checklistId,
    });

    dispatch(
      slice.actions.deleteChecklist({
        cardId,
        checklistId,
      })
    );
  };

export const addCheckItem =
  (cardId: string, checklistId: string, name: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post('/api/kanban/board/checkitems/new', {
      cardId,
      checklistId,
      name,
    });

    dispatch(
      slice.actions.addCheckItem({
        cardId,
        checklistId,
        ...response.data,
      })
    );
  };

export const updateCheckItem =
  (
    cardId: string,
    checklistId: string,
    checkItemId: string,
    update: {
      name?: string;
      state?: 'complete' | 'incomplete';
    }
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post('/api/kanban/board/checkitems/update', {
      cardId,
      checklistId,
      checkItemId,
      update,
    });

    dispatch(
      slice.actions.updateCheckItem({
        cardId,
        checklistId,
        ...response.data,
      })
    );
  };

export const deleteCheckItem =
  (cardId: string, checklistId: string, checkItemId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await axios.post('/api/kanban/board/checkitems/remove', {
      cardId,
      checklistId,
      checkItemId,
    });

    dispatch(
      slice.actions.deleteCheckItem({
        cardId,
        checklistId,
        checkItemId,
      })
    );
  };
