import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDisponibilidade } from '../types/disponibilidade';

interface DisponibilidadeState {
  disponibilidade: IDisponibilidade;
}

const initialState: DisponibilidadeState = {
  disponibilidade: {
    ExibirDisponibilidade: false,
    UnidadeNegocio: '',
    MaterialDisponibilidade: {
      DescricaoCompleta: '',
      DescricaoEspecif: '',
      Imagem: '',
      ImagemCDN: '',
      Codigo: '',
      Descricao: '',
      CodigoConfigurador: '',
      CodigoUnidadeMedida: '',
      UnidadeMedida: {
        Codigo: '',
        Descricao: '',
        Inteiro: 0,
        Decimal: 0,
        NumeroDecimal: 0,
      },
      UtilizaGrade: '',
      CodigoEspecificacao1: '',
      CodigoEspecificacao2: '',
      CodigoEspecificacao3: '',
      CodigoGrupo: '',
      CodigoSubGrupo: '',
      Complemento: '',
      Configurador: '',
      Criacao: '',
      GarantiaVenda: 0,
      Grupo: '',
      Modificacao: '',
      Referencia: '',
      Tipo: '',
      TipoDescricao: '',
    },
    Lote: '',
    Numeracao: '',
  },
};

const slice = createSlice({
  name: 'ordemProducao',
  initialState,
  reducers: {
    setDisponibilidade(
      state: DisponibilidadeState,
      action: PayloadAction<IDisponibilidade>
    ): void {
      const {
        ExibirDisponibilidade,
        MaterialDisponibilidade,
        UnidadeNegocio,
        CentroArmazenagem,
        Lote,
        Numeracao,
      } = action.payload;

      state.disponibilidade.ExibirDisponibilidade = ExibirDisponibilidade;
      state.disponibilidade.MaterialDisponibilidade = MaterialDisponibilidade;
      state.disponibilidade.UnidadeNegocio = UnidadeNegocio;
      state.disponibilidade.CentroArmazenagem = CentroArmazenagem;
      state.disponibilidade.Lote = Lote;
      state.disponibilidade.Numeracao = Numeracao;
    },
  },
});

export const { reducer } = slice;

export const { setDisponibilidade } = slice.actions;
