import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HistoryNavigationState {
  pathname: string;
}

const initialState: HistoryNavigationState = {
  pathname: '/',
};

const slice = createSlice({
  name: 'historyNavigation',
  initialState,
  reducers: {
    setHistoryNavigation(
      state: HistoryNavigationState,
      action: PayloadAction<string>
    ) {
      state.pathname = action.payload;
    },
  },
});

export const { reducer } = slice;

export const { setHistoryNavigation } = slice.actions;
